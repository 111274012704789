<template>
	<div class="jobhunting">
		<Header style="display:inline-block;" activeUrl="/jobhunting"></Header>
		<div class="job-position">
			<div class="position-content">
				<div class="center">
					<div class="position-tab">
						<div class="sort_order">
							<div class="position-tab-a" v-for="(Sitem, Sindex) in sortList" :key="Sindex">
								<div class="title">{{ Sitem.name }}：</div>
								<div class="info flex1">
									<cu-tab-select :data="Sitem.data" v-model="Sitem.clcik" @change="SelectTap()">
									</cu-tab-select>
								</div>
							</div>
						</div>
						<div class="position-tab-content">
							<div class="resumelist">
								<div class="resume" v-for="(item, resume) in PerJobList" :key="resume"
									@click="goto('/jobdetail', { id: item.oF_ID })">
									<div class="recruitment">
										<div class="recruitment_left">
											<div class="recruitment-posi-title" style="">
												<div class="recruitment-posi-name">{{item.oF_POSI_NAME}}</div>
												<div style="padding-left: 20px;font-size: 12px;color:#0096ff">
													{{item.date}}
												</div>
											</div>
											<div class="text-tags" style="margin-top:10px;">
												<span style="color:#F68E08">{{getsalary(item)}}</span>
												<span>{{getGzjyName(item)}}</span>
												<span>{{item.oF_WORKAREAName}}</span>
											</div>
										</div>
										<div classs="recruitment_right" style="display:flex;">
											<div>
												<div style="font-size: 16px;color: #467DF2;">
													<span>{{item.com_name}}</span>
												</div>
												<div class="text-tags" style="margin-top:10px;">
													<span>{{item.com_nature}}</span>
													<span>{{item.com_scale}}</span>
												</div>
											</div>
										</div>
									</div>
									<div class="treatment">
										<div class="tag">
											<el-tag v-for="(itemfl,i) in getFlArr(item.oF_FL)" :key="i" color="#E6F0FF"
												style="margin-right:14px;height: 24px;height: 24px;color: rgba(137, 137, 137, 100);line-height: 24px;">
												{{itemfl}}
											</el-tag>
										</div>

										<div
											style="color: rgba(137, 137, 137, 100);font-size: 14px; padding-right: 19px;flex:1;text-align: right;">
											<span class="text-tags">
												<span>{{item.com_hytype}}</span>
											</span>
										</div>
									</div>
								</div>
							</div>

							<cu-pager :form.sync="form" :total="total" @change="handleCurrentChange"
								@sizeChange="handleSizeChange"></cu-pager>
						</div>

						<el-empty style="background: #fff" v-if="PerJobList.length < 1" :image-size="200"></el-empty>
					</div>
					<div class="recent">
						<div class="recentBrowsing">
							<div class="title">
								<img src="../../assets/img/hottel.png" style="height: 20px;">
								<span>热招岗位</span>
							</div>
							<div class="occupation" v-for="item in positiondata" :key="item.oF_ID"
								@click="goto('/company1', { id: item.oF_COM_ID })">
								<div class="occupation_company">
									<div class="occupation_job_name">{{ item.oF_POSI_NAME }}</div>
									<div class="salary">
										<span>{{getsalary(item)}}</span>
									</div>
								</div>
								<div class="occupation_company_name">{{ item.com_name }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<Footer></Footer>
	</div>
</template>

<script>
	import {
		Getindexsearch,
		Addresume,
		Getposilist,
		Gethotcompanylist,
		GetOfferList
	} from "@/api/home";
	import {
		favuser,
		cancelfavuser,
		ispaybycomid,
	} from "@/api/company";
	import {
		isnull,
		handleCofirm,
	} from "@/assets/js/index";
	import utils from "@/assets/js/utils";
	import global from "@/assets/js/globalconfig";
	import Header from "@/components/header1.vue";
	import Footer from "@/components/footer1.vue";
	import CuTabSelect from "../../components/CuTabSelect.vue";
	import CuDropSelect from "../../components/CuDropSelect.vue";
	import CuPager from '../../components/CuPager.vue';
	export default {
		components: {
			Header,
			Footer,
			CuTabSelect,
			CuDropSelect,
			CuPager
		},

		data() {
			return {
				defimgurl: global.baseimgurl,
				form: {
					searchKey: "",
					pageNumber: 1,
					pageSize: 7,
				},
				total: 0,
				cur: 0,
				PerJobList: [],
				curr: 0,

				positiondata: [],
				isfieldchage: false,
				field1: 18,
				stateyear: "",
				field2: 80,
				endyear: "",
				user: {},
				sortList: [{
					clcik: "",
					name: "排序方式",
					data: [{
							name: "综合",
							value: ""
						},
						{
							name: "薪资",
							value: "薪资"
						},

					],
				}],
				idcompanylogin: false,
				ispaybycomiddata: 0,
			};
		},
		watch: {
			field1(value, o) {
				if (value < 18) {
					this.field1 = 18;
				} else if (value > 80) {
					this.field1 = 80;
				} else if (value > this.field2) {
					this.field1 = this.field2;
				} else {
					this.field1 = value;
				}
			},
			field2(value, o) {
				if (value < 18) {
					this.field2 = 18;
				} else if (value > 80) {
					this.field2 = 80;
				} else if (value < this.field1) {
					this.field2 = this.field1;
				} else {
					this.field2 = value;
				}
			},
		},
		mounted() {
			window.addEventListener("scroll", this.showbtn, true);
		},
		created() {
			if (localStorage.getItem("userinfo")) {
				this.user = JSON.parse(localStorage.getItem("userinfo"));
				if (this.user.coM_ID) {
					this.idcompanylogin = true;
					this.ispaybycomid();
				}
			}
			this.form.searchKey = this.$route.query.searchKey;
			this.GetList();
			this.GetOfferList();
		},
		methods: {
			getPublishTime(time) {
				return time.substring(11, 16) + ' 发布';
			},
			getFlArr(a) {
				let arr = [];
				if (a && a != null && a != '') {
					let n = a.split(',');
					n.some((v) => {
						if (v != '' && v != null) {
							arr.push(v);
						}
					})
				}
				return arr;
			},
			getsalary(item) {
				if (item.oF_SALARY_MY == 1) {
					return '面议'
				} else {
					return this.setsalary(item.oF_SALARY, item.oF_SALARYE)
				}
			},
			getGzjyName(item) {
				if (item.oF_GZJY == '有') {
					return '有经验';
				}
				return item.oF_GZJY;
			},
			goto(url, data) {
				this.resolvewin(url, data, 2);
			},
			ispaybycomid() {
				var _this = this;
				ispaybycomid({
					id: this.user.coM_ID
				}).then((res) => {
					_this.ispaybycomiddata = res.data;
				});
			},
			stringToDates(data) {
				return utils.stringToDate(data);
			},
			enter(i) {
				this.curr = i;
			},
			leave() {
				this.curr = null;
			},
			goPage(path) {
				this.$router.push(path);
			},
			SelectTap() {
				this.form.pageNumber = 1;
				this.GetList();
			},
			search() {
				this.form.pageNumber = 1;
				this.GetList();
			},
			GetList() {
				var _this = this;
				var par = {
					PageSize: _this.form.pageSize,
					PageNumber: _this.form.pageNumber,
					id: this.user.coM_ID,
					searchKey: _this.form.searchKey,
					order: _this.sortList[0].clcik,
					showLoadType: ".position-tab-content",
				};
				Getindexsearch(par).then((res) => {
					if (res.success) {
						res.data.rows.forEach((element) => {
							element.hy = this.utils.handleDateHY(element.peR_LASTLOAD);
						});
						_this.total = res.data.total;
						_this.PerJobList = res.data.rows;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			//人才收藏
			favuser(id) {
				var _this = this;
				var par = {
					id: this.user.coM_ID,
					uid: id,
				};
				favuser(par).then((res) => {
					if (res.success) {
						this.GetList();

						_this.$message.success("简历已收藏!");
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			//取消人才收藏
			cancelfavuser(id) {
				var _this = this;
				handleCofirm('确认取消收藏该简历吗？', 'warning', ).then(res => {
					var par = {
						id: this.user.coM_ID,
						uid: id,
					};
					cancelfavuser(par).then((res) => {
						if (res.success) {
							this.GetList();

							_this.$message.success("已取消收藏!");
						} else {
							_this.$message.error(res.msg);
						}
					});
				}).catch(err => {
					//console.log('err', err) // cancel
				})
			},
			handleSizeChange(val) {
				this.form.pageNumber = 1;
				this.form.pageSize = val;
				this.GetList();
				this.backtop();
			},
			handleCurrentChange(val) {
				this.form.pageNumber = val;
				this.GetList();
				this.backtop();
			},
			setuserlogo(url) {
				return isnull(url) ?
					require("@/assets/img/logos.png") :
					url.indexOf("http") < 0 ?
					this.defimgurl + url :
					url;
			},
			setsalary(a, b) {
				var data = [];
				if (!isnull(a)) {
					if (a < 100 && a > 0) {
						data.push(a + "k");
					} else if (a >= 1000) {
						data.push(a / 1000 + "k");
					} else {
						data.push(a);
					}
				}
				if (!isnull(b)) {
					if (b < 100 && b > 0) {
						data.push(b + "k");
					} else if (b >= 1000) {
						data.push(b / 1000 + "k");
					} else {
						data.push(b);
					}
				}
				if (data.length === 0) {
					return "面议";
				} else {
					return data.join("-");
				}
			},

			backtop() {
				var timer = setInterval(function() {
					let osTop =
						document.documentElement.scrollTop || document.body.scrollTop;
					let ispeed = Math.floor(-osTop / 5);
					document.documentElement.scrollTop = document.body.scrollTop =
						osTop + ispeed;
					this.isTop = true;
					if (osTop === 0) {
						clearInterval(timer);
					}
				}, 30);
			},

			talk(url, data, type = 2) {
				this.resolvewin(url, data, type);
			},

			GetOfferList() {
				var _this = this;
				GetOfferList({
					pageSize: 5
				}).then((res) => {
					if (res.success) {
						_this.positiondata = res.data.rows;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			setOFEDUREQ(data) {
				return isnull(data) ? "不限" : data;
			},
			clearsel() {
				this.form = {
					searchKey: "",
					pageNumber: 1,
					pageSize: 10,
				};
				this.GetList();
			},

			salary(data) {
				if (data.indexOf("-") == -1) {
					return "面议";
				} else {
					return data;
				}
			},

		},
	};
</script>

<style lang="scss" scoped>
	.job-search {
		position: relative;
		width: 100%;
		background: #fff;
		border-top: 1px solid #eee;
		margin-bottom: 16px;

		.search-content {
			width: 1200px;
			margin: 0px auto;
			padding: 15px 0px;
			display: flex;
			justify-content: space-between;

			.search {
				flex: 1;
				width: 100%;

				.el-form {


					.el-form-item ::v-deep {
						margin-bottom: 0;

						.el-form-item__content {
							display: flex;
							align-items: stretch;

							.search-input {
								display: flex;
								border: 1px solid #e5e5e5;

								.el-input {
									.el-input__inner {
										border: none;
										width: 600px;
									}
								}
							}

							.search-btn {
								.el-button {
									background: #66b1ff;
									border-radius: 0;
									height: 100%;
									padding: 12px 30px;
									width: 150px;
								}
							}
						}
					}
				}
			}

			.search-hottel {
				display: flex;
				align-items: center;
				font-size: 14px;

				img {
					width: 22px;
					height: 22px;
					margin-right: 4px;
				}

				span {
					font-size: 18px;
					color: #66b1ff;
				}
			}
		}

		.select-content {
			width: 1200px;
			margin: 0px auto;
			padding-bottom: 15px;
			display: flex;
			justify-content: space-between;

			.select-cell {
				.el-form {
					.el-form-item ::v-deep {
						margin-bottom: 0;

						.select-input {
							.el-select {
								margin-right: 60px;

								.el-input__inner {
									width: 110px;
									padding-left: 0;
									border: none;
								}
							}
						}
					}
				}
			}

			.select-clear {
				font-size: 14px;
				color: #787878;
				line-height: 40px;
				cursor: pointer;
			}
		}
	}

	.job-position {
		position: relative;
		width: 1200px;
		margin: 0px auto;
		padding: 20px 0;
		padding-top: 0;

		.position-content {
			.position-tab-title {
				background: #fff;
				margin-bottom: 20px;
				padding: 20px 20px 1px 20px;

				.position-tab-a {
					display: flex;
					font-size: 14px;
					margin-bottom: 20px;

					.title {
						font-weight: bold;
						color: #333;
						padding: 5px 10px;
						min-width: 90px;
					}

					.info {
						span {
							margin: 0 20px;
						}

						.info-a {
							cursor: pointer;
							padding: 5px 10px;
							margin-right: 10px;
						}

						.info-Select {
							color: #fff;
							background-color: #2778f8;
							border-radius: 4px;
						}
					}

					.el-dropdown-link {
						cursor: pointer;
					}
				}




			}

			.center {
				display: flex;

				.position-tab {
					position: relative;
					// display: flex;
					align-items: stretch;
					width: 70%;

					.sort_order {
						width: 100%;
						height: 28px;
						background: #fff;
						margin-bottom: 20px;
						display: flex;
						padding-top: 10px;
						padding-bottom: 10px;

						//   box-sizing: border-box;
						.position-tab-a {
							display: flex;
							font-size: 14px;
							margin-bottom: 20px;
							margin-left: 20px;

							.title {
								font-weight: bold;
								color: #333;
								padding: 5px 10px;
								min-width: 90px;
							}

							.info {
								span {
									margin: 0 20px;
								}

								.info-a {
									cursor: pointer;
									padding: 5px 10px;
									margin-right: 10px;
								}

								.info-Select {
									color: #fff;
									background-color: #2778f8;
									border-radius: 4px;
								}
							}

							.el-dropdown-link {
								cursor: pointer;
							}
						}

						.position-tab-a1 {
							display: flex;
							font-size: 14px;
							// margin-bottom: 20px;
							margin-left: 97px;
							margin-top: 5px;

							.title {
								font-weight: bold;
								color: #333;
								// padding: 5px 10px;
								// min-width: 90px;
							}


						}
					}

					.position-tab-content {

						width: 100%;
						background: #fff;
						padding-top: 20px;

						.resume {
							width: 95%;
							// padding: 20px;
							box-sizing: border-box;
							background: white;
							margin-bottom: 10px;
							cursor: pointer;
							margin-left: 2.5%;

							border: 1px solid #eee;

							.recruitment {
								display: flex;
								justify-content: space-between;
								padding: 15px 20px;

								.recruitment_left {
									flex: 1;

									.recruitment-posi-title {
										font-size: 16px;
										color: rgba(16, 16, 16, 100);
										display: flex;

										.recruitment-posi-name {
											min-width: 150px;
										}
									}
								}

								.recruitment_right {
									display: flex;
								}
							}

							.treatment {
								width: 100%;
								background: #f8f8f8;
								display: flex;
								padding: 10px 0;

								.tag {
									padding-left: 13px;
									margin: auto 0;
									max-width: 70%;
									overflow: hidden;
									word-break: keep-all;
									white-space: nowrap;
									text-overflow: ellipsis;

									.el-tag {
										margin-bottom: 4px;
									}
								}

							}

						}

						.resume:hover {
							box-shadow: 0 0 6px rgba(39, 107, 242, 0.3);
						}
					}

					.position-other {
						width: 310px;
						margin-left: 12px;

						.edit-resume {
							padding: 16px 24px;
							background: #fff;
							margin-bottom: 12px;

							a {
								color: #66b1ff;
								padding: 12px;
								background: #fff;
								border: 1px solid #66b1ff;
								display: block;
								text-decoration: none;
							}
						}

						.other-ad {
							margin-bottom: 12px;
							display: flex;

							img {
								width: 100%;
							}
						}

						.other-copmpany {
							background: #fff;
							padding: 16px;

							.other-company-title {
								font-size: 18px;
								color: #333;
								padding-bottom: 16px;
								margin-bottom: 16px;
								text-align: left;
								border-bottom: 1px solid #eee;
							}

							.other-company-cell {
								display: flex;
								flex-wrap: wrap;
								justify-content: center;

								.company-item {
									width: 275px;
									padding: 12px 0;
									background: #f2f2f2;
									margin-bottom: 16px;
									position: relative;
									overflow: hidden;
									cursor: pointer;

									.company-logo {
										height: 50px;
										text-align: center;

										img {
											height: 50px;
										}
									}

									.company-name {
										font-size: 14px;
										line-height: 32px;
										color: #333;
										text-align: center;
										width: 70%;
										margin: 0 auto;
										overflow: hidden;
										text-overflow: ellipsis;
										white-space: nowrap;
									}

									.company-num {
										padding: 8px 0px;
										border-radius: 2px;
										border: 1px solid #eee;
										font-size: 12px;
										background: #fff;
										width: 180px;
										margin: 0px auto;
										text-align: center;

										b {
											color: #ff0000;
										}
									}
								}

								.company-item:last-child {
									margin-bottom: 0;
								}
							}
						}
					}
				}


				.recent {
					width: 35%;
					margin-left: 2%;

					.recentBrowsing {
						width: 100%;
						// height: 455px;
						background-color: #fff;

						.title {
							color: rgba(16, 16, 16, 100);
							font-size: 16px;
							width: 97%;
							height: 50px;
							border-bottom: 1px solid #eee;
							margin-left: 3%;
							line-height: 50px;
							display: flex;
							align-items: center;

							span {
								margin-left: 14px;
							}
						}

						.occupation {
							cursor: pointer;
							color: rgba(16, 16, 16, 100);
							font-size: 14px;
							width: 94%;
							border-bottom: 1px solid #eee;
							margin-left: 3%;
							margin-right: 3%;
							padding: 15px 10px;
							justify-content: space-between;

							.occupation_company_name {
								padding-top: 5px;
								color: rgba(137, 137, 137, 100);
							}

							.occupation_company {
								display: flex;
								align-items: center;

								.occupation_job_name {
									font-size: 16px;
									flex: 1;
								}
							}

							.salary {
								margin-right: 15px;
								color: #ff7630;
								font-size: 16px;

							}

						}
					}

					.advertisement {
						width: 100%;
						height: 252px;
						background: #0096FF;
						margin-top: 16px;
					}

				}
			}

		}
	}
</style>
