<template>
  <div style="" class="uc-Page">
                <el-pagination hide-on-single-page @size-change="handleSizeChange" @current-change="handleCurrentChange"
                  :current-page="form.pageNumber" :page-sizes="[10, 20, 30, 40]" :page-size="form.pageSize"
				  background
                  layout="  prev, pager, next" :total="total">
                </el-pagination>
              </div>
</template>
<script>
export default {
    props:{
        form:{pageNumber:1,pageSize:20},
        total:Number
    },
    methods:{
        handleSizeChange(a){
            this.$emit('sizeChange',a);
        },
        handleCurrentChange(a){
            this.$emit('change',a);
        }
    }
    
}
</script>
<style lang="scss"  scoped>
.uc-Page{
    text-align: center;padding-bottom: 30px;padding-top: 60px;
::v-deep .el-pagination.is-background .el-pager li
{
    background:none;
    border:1px solid #d9d9d9;
    border-radius: 5px;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active
{
    background: #409EFF;
}
::v-deep .el-pagination.is-background .btn-next
{
    background:none;
    border:1px solid #d9d9d9;
   height: 32px;
    line-height: 32px;
    border-radius: 4px;
}
 ::v-deep .el-pagination.is-background .btn-prev
{
    background:none;
    border:1px solid #d9d9d9;
   height: 32px;
    line-height: 32px;
    border-radius: 4px;
}
::v-deep .el-pager li{
    height: 32px;
    line-height: 32px;
}
}

</style>