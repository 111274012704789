<template>
<div class="info flex1">
<div v-for="(item, index) in data" :key="index"
								@click="SelectTap(item)" :class="[
                  'info-a',
                  value == item.value ? 'info-Select' : '',
                ]">
								{{ item.name }}
							</div>
  </div>
</template>
<script>
export default{
props:{
    value:String | Number,
    data:Array,

    },
    methods:{
        SelectTap(item){
            this.$emit('input',item.value);
            this.$emit('change',item);
        }
    }
}
</script>
<style lang="scss" scoped>
.flex1{flex: 1;}
.info{
  span {
							margin: 0 20px;
						}

						.info-a {
							cursor: pointer;
							padding: 3px 8px;
							margin-right: 16px;
              color: #898989;
              font-size: 13px;
              margin-bottom: 4px;
						}

						.info-Select {
							color: #fff;
							background-color: #307AE8;
							border-radius: 2px;
						}
}
</style>